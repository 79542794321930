import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import type { Observable } from 'rxjs';
import type { Underlying } from '../../neosModel';
import type { RetrieveUnderlyingApi } from './underlyingEpics';
import type { OnyxUnderlying } from '@/neos/business/underlyingInfo/underlyingInfoOnyxModel.ts';

export const createRetrieveUnderlyingApi = (http: SgmeHttp): RetrieveUnderlyingApi => ({
  retrieveUnderlyingByBloombergCode: (bloombergCode: string): Observable<Underlying[]> => {
    const url: string = `api/referential/underlyings?bloombergCode=${bloombergCode}`;
    return http.get<Underlying[]>({
      url,
    });
  },
  bulkRetrieveUnderlyingByBloombergCodes: (bloombergCodes: string[]): Observable<Underlying[]> => {
    const url: string = `api/referential/underlyings?bloombergCodes=${bloombergCodes.join(',')}`;
    return http.get<Underlying[]>({
      url,
    });
  },
  retrievePartialUnderlyingByGalaxyCodes: (galaxyCodes: string[]): Observable<OnyxUnderlying[]> => {
    const url: string = 'api/referential/underlyings';
    return http.post<OnyxUnderlying[]>({
      url,
      body: galaxyCodes,
    });
  },
  batchRetrieveUnderlyingByBloombergCodes: (
    bloombergCodes: string[],
  ): Observable<OnyxUnderlying[]> => {
    const url: string = `api/referential/underlyings/findByBloombergCodes`;
    return http.post<OnyxUnderlying[]>({
      url,
      body: bloombergCodes,
    });
  },
});
