import type { Thunk } from '@/bootstrap/thunks';
import { valueBy } from '@/util/array/arrayUtils';
import { flatMap, uniq, values } from 'lodash';
import type { ChainOptions } from '../neosActions';

export function createRequestMissingUnderlyingsThunk(
  rfqIds: string[],
  chainOptions?: ChainOptions,
  otherUnderlyingIdsToRetrieve: string[] = [],
): Thunk {
  return function requestMissingUnderlyingsThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createUnderlyingInfoRequestedAction, createPartialUnderlyingInfoRequestedAction },
      },
    },
  ) {
    const appState = getState();

    const elsBasketRfqIds = rfqIds.filter(rfqId =>
      selectors.isStrategyElsBasket(appState, rfqId, selectors),
    );

    const basketMissingUdlsByRfqId = valueBy(elsBasketRfqIds, rfqId =>
      selectors.getUnderlyingsIdsWithoutAnyInfo(appState, rfqId, selectors),
    );
    const allBasketRfqMissingUnderlyingIds = flatMap(basketMissingUdlsByRfqId, underlyingIds => {
      return values(underlyingIds);
    });
    const basketRfqMissingUnderlyingIds = uniq(allBasketRfqMissingUnderlyingIds);

    if (basketRfqMissingUnderlyingIds.length > 0) {
      const affectedBasketProductRfqIds = elsBasketRfqIds.filter(
        rfqId => basketMissingUdlsByRfqId[rfqId].length > 0,
      );

      if (affectedBasketProductRfqIds.length) {
        dispatch(
          createPartialUnderlyingInfoRequestedAction(
            affectedBasketProductRfqIds,
            basketRfqMissingUnderlyingIds,
            chainOptions,
          ),
        );
      }
    }

    const otherRfqIds = rfqIds.filter(
      rfqId => !selectors.isStrategyElsBasket(appState, rfqId, selectors),
    );

    const otherMissingUdlsByRfqId = valueBy(otherRfqIds, rfqId =>
      selectors.getUnderlyingsIdsWithoutFullInfo(appState, rfqId, selectors),
    );
    const otherRfqMissingUnderlyingIds = flatMap(otherMissingUdlsByRfqId, underlyingIds => {
      return values(underlyingIds);
    });
    const allMissingUnderlyingIds = otherRfqMissingUnderlyingIds.concat(
      otherUnderlyingIdsToRetrieve,
    );

    const missingUnderlyingIds = uniq(allMissingUnderlyingIds);

    if (missingUnderlyingIds.length > 0) {
      const affectedRfqIds =
        otherUnderlyingIdsToRetrieve.length > 0
          ? rfqIds
          : otherRfqIds.filter(rfqId => otherMissingUdlsByRfqId[rfqId].length > 0);

      if (affectedRfqIds.length) {
        dispatch(
          createUnderlyingInfoRequestedAction(affectedRfqIds, missingUnderlyingIds, chainOptions),
        );
      }
    } else {
      const dispatchables = chainOptions?.success?.dispatchables ?? [];
      dispatchables.forEach(toBeDispatched => dispatch(toBeDispatched));
    }
  };
}
