import type { Dispatchable, Thunk } from '@/bootstrap/thunks';

export function createNeosLoadPartialUnderlyingInfoThunk(
  rfqId: string,
  underlyingId: string | undefined,
  successDispatchable: Dispatchable,
): Thunk {
  return function neosLoadPartialUnderlyingInfoThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createPartialUnderlyingInfoRequestedAction },
      },
    },
  ) {
    const appState = getState();
    const underlyingInfo = underlyingId
      ? selectors.getUnderlyingInfo(appState, underlyingId)
      : undefined;

    if (underlyingId && !underlyingInfo) {
      dispatch(
        createPartialUnderlyingInfoRequestedAction([rfqId], [underlyingId], {
          success: { dispatchables: [successDispatchable] },
        }),
      );
    } else {
      dispatch(successDispatchable);
    }
  };
}
