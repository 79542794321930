import { z, ZodIssueCode } from 'zod';
import { fromError, fromZodIssue, type ZodError } from 'zod-validation-error';
import type { ExcelNumberFormat } from '@/neos/business/ui/userPreferences/userPreferencesUiModel.ts';
import type { ImportMode } from '@/util/excel/excel.ts';

export type TypeToZod<T> = Required<{
  [K in keyof T]: T[K] extends string | number | boolean | null | undefined
    ? undefined extends T[K]
      ? z.ZodOptional<z.ZodType<Exclude<T[K], undefined>>>
      : z.ZodType<T[K]>
    : z.ZodObject<TypeToZod<T[K]>>;
}>;

export const createZodObject = <T>(obj: TypeToZod<T>) => {
  return z.object(obj);
};

/**
 * Allows to convert a const array of strings to a zod schema of those specific strings only.
 * @param constants const array of strings
 */
export function unionOfLiterals<T extends string | number>(constants: readonly T[]) {
  const literals = constants.map(x => z.literal(x)) as unknown as readonly [
    z.ZodLiteral<T>,
    z.ZodLiteral<T>,
    ...z.ZodLiteral<T>[],
  ];
  return z.union(literals);
}

export function formatZodError(error: ZodError, prefix?: string): string {
  const formattedError = fromError(error, {
    prefix,
    issueSeparator: '<br />',
    prefixSeparator: ':<br />',
  }).toString();

  // eslint-disable-next-line no-console
  console.error(formattedError.replaceAll('<br />', '\n'));
  return formattedError;
}

export const customStringNumberErrorMap: z.ZodErrorMap = (issue, ctx) => {
  switch (issue.code) {
    case ZodIssueCode.invalid_type: {
      const receivedIssueIsNan = issue.expected === 'number' && issue.received === 'nan';

      return {
        message: receivedIssueIsNan
          ? `Expected numeric value`
          : (issue.message ?? ctx.defaultError),
      };
    }
    default: {
      const validationError = fromZodIssue({
        ...issue,
        message: issue.message ?? ctx.defaultError,
      });

      return {
        message: validationError.message,
      };
    }
  }
};

export function getZodStringNumber(excelNumberFormat: ExcelNumberFormat, importedFrom: ImportMode) {
  z.setErrorMap(customStringNumberErrorMap);
  return (zodNumber?: z.ZodNumber) =>
    z.preprocess(input => {
      if (typeof input !== 'string' || input === '') {
        return NaN;
      }

      if (importedFrom === 'file') {
        return Number(input);
      }

      return excelNumberFormat === 'EN'
        ? parseEnglishNumberFormat(input)
        : parseFrenchNumberFormat(input);
    }, zodNumber ?? z.number());
}

function parseEnglishNumberFormat(input: string): number {
  const isEnglishFormat = input.match(new RegExp(/^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/));
  if (isEnglishFormat) {
    if (input.includes(',')) {
      input = input.replace(/,/g, '');
    }

    return parseFloat(input);
  }
  return NaN;
}

function parseFrenchNumberFormat(input: string): number {
  const isFrenchFormat = input.match(new RegExp(/^(\d+|\d{1,3}( \d{3})*)(,\d+)?$/));
  if (isFrenchFormat) {
    input = input.replace(/ /g, '');
    input = input.replace(/,/g, '.');

    return parseFloat(input);
  }
  return NaN;
}
