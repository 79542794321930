import type { ZodObject } from 'zod';
import { z } from 'zod';
import type {
  DividendLegPeriodDates,
  EquityLegPeriodDates,
  RateLegPeriodDates,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';

const optionalStringDate = z.optional(z.string().date());

export const scheduleEquityDatesSchema = z.object({
  startDate: optionalStringDate,
  endDate: optionalStringDate,
  paymentDate: optionalStringDate,
});

export const scheduleRateDatesWtihoutOvernightSchema = z.object({
  fixingDate: optionalStringDate,
  startDate: optionalStringDate,
  endDate: optionalStringDate,
  paymentDate: optionalStringDate,
});

export const scheduleRateDatesSchema = z.object({
  firstFixingDate: optionalStringDate,
  fixingDate: optionalStringDate,
  startDate: optionalStringDate,
  endDate: optionalStringDate,
  paymentDate: optionalStringDate,
});

export const scheduleDividendDatesSchema = z.object({
  startDate: optionalStringDate,
  endDate: optionalStringDate,
  paymentDate: optionalStringDate,
  theoreticalPeriodPaymentDate: optionalStringDate,
});

export const validateScheduleSchema = (
  equityDates: Partial<EquityLegPeriodDates & DividendLegPeriodDates & RateLegPeriodDates>,
  scheduleSchema: ZodObject<any>,
) => {
  return scheduleSchema.safeParse(equityDates);
};
