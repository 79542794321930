import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isStatusAtOrAfterOrderAccepted } from '@/neos/business/referenceData/status/statusSelector';

export type ExecutedSpotModel = {
  underlyingId: string;
  executedSpot: number | undefined;
  isReadonly: boolean;
};

export type ExecutedSpotReferenceModel = {
  underlyingsExecutedSpotModel: ExecutedSpotModel[];
  isDisplayed: boolean;
};

export function getExecutedSpotModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): ExecutedSpotReferenceModel {
  const isFeatureToggleEnabled = selectors.isFeatureToggleEnabled(
    state,
    'neos.future.executedspot.enabled',
  );

  const references = selectors.getNotAggregatedReferences(state, rfqId, selectors);

  const isRfqReadonly = selectors.isReadOnlyRfq(state, rfqId);
  const isRfqReadOnlyAtCurrentWorkflow = selectors.isRfqReadOnlyAtCurrentWorkflow(
    state,
    rfqId,
    selectors,
  );

  const isFutureStrategy = selectors.isMasterStrategyFuture(state, rfqId, selectors);

  const underlyingsExecutedSpotModel = references.map((reference): ExecutedSpotModel => {
    const underlyingInfo = selectors.getFullUnderlyingInfo(state, reference.underlyingId);

    return {
      underlyingId: underlyingInfo ? underlyingInfo.id : reference.underlyingId,
      executedSpot: reference.executedSpot,
      isReadonly: isRfqReadonly || isRfqReadOnlyAtCurrentWorkflow,
    };
  });

  return {
    underlyingsExecutedSpotModel,
    isDisplayed:
      isFeatureToggleEnabled &&
      isStatusAtOrAfterOrderAccepted(state, rfqId, selectors) &&
      !!underlyingsExecutedSpotModel.length &&
      isFutureStrategy,
  };
}
