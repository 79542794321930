import type { Thunk } from '@/bootstrap/thunks.ts';
import type { ElsBasket } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';

export function createUpdateElsBasketCompositionUnderlyingThunk(
  product: ElsBasket,
  rfqId: string,
  underlyingId: string | undefined,
  index: number,
): Thunk {
  return function updateElsBasketCompositionUnderlying(
    dispatch,
    _,
    {
      thunks: {
        neos: {
          createUpdateElsBasketCompositionThunk,
          createNeosLoadPartialUnderlyingInfoThunk,
          createDefaultBasketProductThunk,
        },
      },
    },
  ) {
    if (underlyingId === undefined) {
      return;
    }

    dispatch(createUpdateElsBasketCompositionThunk(product, index, 'underlyingId', underlyingId));

    dispatch(
      createNeosLoadPartialUnderlyingInfoThunk(
        rfqId,
        underlyingId,
        createDefaultBasketProductThunk(rfqId),
      ),
    );
  };
}
