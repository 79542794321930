import type { AppState } from '@/bootstrap/state';

export function getUnderlyingDividendFutureMaturities(
  state: AppState,
  underlyingId: string,
): string[] {
  const underlyingStateInfo = state.underlyingState[underlyingId];

  return underlyingStateInfo && underlyingStateInfo.hasAllUnderlyingInfo
    ? underlyingStateInfo.dividendFuture.maturityInfo
    : [];
}
