import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { Portfolio } from '@/neos/components/rfq/strategyDetails/Portfolio/Portfolio';
import { CounterpartPortfolio } from '@/neos/components/rfq/strategyDetails/StrategyDetails';
import { If } from '@/neos/components/share/if/if';
import { AllocationLegSizeWithExecTooltip } from '../allocInfo/AllocationLegSizeWithExecTooltip';
import { AveragePrice } from '../allocInfo/AveragePrice';
import { ClientWay } from '../allocInfo/ClientWay';
import { LegBookingId } from '../allocInfo/LegBookingId/LegBookingId';
import { Maturity } from '../allocInfo/Maturity';
import { OptionStyle } from '../allocInfo/OptionStyle';
import { ProductType } from '../allocInfo/ProductType';
import { PtmmPrice } from '../allocInfo/PtmmPrice/PtmmPrice';
import { SettlementDate } from '../allocInfo/SettlementDate';
import { Strike } from '../allocInfo/Strike';
import { Underlying } from '../allocInfo/Underlying';
import styles from './LegDescription.module.scss';
import { Cashflows } from '@/neos/components/rfq/postNego/allocs/otcAllocations/secondaryAllocations/cashflows/Cashflows.tsx';
import { StartDate } from '@/neos/components/rfq/postNego/allocs/allocInfo/StartDate.tsx';

export interface LegDescriptionOwnProps {
  rfqId: string;
  strategyId: string;
  legId: string;
}

export function LegDescription(props: LegDescriptionOwnProps) {
  const isEls = useAppSelector(state =>
    selectors.isElsStrategy(state, props.strategyId, selectors),
  );
  const isInternal =
    useAppSelector(state => selectors.getRfqData(state, props.rfqId)).internal === true;

  return (
    <div
      className={`${styles['leg-description']} mb-2 text-nowrap`}
      data-e2e="neos-postNego-leg-description"
    >
      <AllocationLegSizeWithExecTooltip {...props} />
      <AveragePrice {...props} />
      <ClientWay {...props} />
      <Underlying {...props} />
      <ProductType {...props} />
      <StartDate {...props} />
      <Maturity {...props} />
      <Strike {...props} />
      <OptionStyle {...props} />
      <SettlementDate {...props} />
      <div>
        <Portfolio {...props} isComponentInLegDescription={true} />
      </div>
      <If condition={isEls && isInternal}>
        <div>
          <CounterpartPortfolio strategyId={props.strategyId} />
        </div>
      </If>
      <LegBookingId {...props} />
      <PtmmPrice {...props} />
      <Cashflows {...props} />
    </div>
  );
}
