import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type { RateLegPeriodDates } from '../rfq/strategy/leg/product/elsProductOnyxModel';
import { scheduleRateDatesWtihoutOvernightSchema } from '@/neos/business/thunks/scheduleImportedKeysValidation.ts';
import { mapFromScheduleImportedData } from '@/neos/business/thunks/mapFromScheduleImportedData.ts';
import { getScheduleSchema } from '@/util/excel/excel.ts';
import { formatZodError } from '@/util/zod/zod-util.ts';

export function createClsImportScheduleDataThunk(
  strategyId: string,
  importedData: unknown[],
): Thunk {
  return function clsImportScheduleDataThunk(
    dispatch,
    getState,
    { selectors, thunks, actionCreators },
  ) {
    const state = getState();
    const excelDateFormat = selectors.selectExcelDateFormat(state.ui.userPreferences);
    const dispatchables: Dispatchable[] = [];

    dispatch(
      actionCreators.neos.strategyUiCrudActions.update(strategyId, {
        showImportScheduleError: false,
      }),
    );
    dispatchables.push(thunks.neos.createRemoveAllRateSchedulePeriodThunk(strategyId));

    const scheduleParsingResult = getScheduleSchema(excelDateFormat).safeParse(importedData);

    if (!scheduleParsingResult.success) {
      const zodError = formatZodError(scheduleParsingResult.error);
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const neosMappedImportedData = mapFromScheduleImportedData(scheduleParsingResult.data);

    try {
      neosMappedImportedData.forEach((line, index) => {
        const rateDates: Partial<RateLegPeriodDates> = {
          fixingDate: line.rateFixingDate,
          startDate: line.rateStartDate,
          endDate: line.rateEndDate,
          paymentDate: line.ratePayDate,
        };

        const rateDatesValidationResult =
          scheduleRateDatesWtihoutOvernightSchema.safeParse(rateDates);

        if (!rateDatesValidationResult.success) {
          const zodError = formatZodError(
            rateDatesValidationResult.error,
            `Error trying to import rate dates at line ${index + 1}, make sure format match your user preferences`,
          );
          throw new Error(zodError);
        }

        const rateDatesSchedule: RateLegPeriodDates = {
          startDate: rateDatesValidationResult.data.startDate ?? '',
          endDate: rateDatesValidationResult.data.endDate ?? '',
          paymentDate: rateDatesValidationResult.data.paymentDate ?? '',
          fixingDate: rateDatesValidationResult.data.fixingDate ?? '',
        };

        dispatchables.push(
          thunks.neos.createAddRateSchedulePeriodThunk(strategyId, rateDatesSchedule),
        );
      });
      dispatch(dispatchables);
    } catch (e: unknown) {
      if (e instanceof Error) {
        // eslint-disable-next-line no-console
        console.error(e.message);
        dispatch(
          thunks.createErrorToasterThunk(
            {
              message: e.message,
            },
            undefined,
          ),
        );
      }
    }
  };
}
