import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export function getUnderlyingsIdsWithoutFullInfo(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): string[] {
  return selectors
    .getRfqAndDeltaStrategiesUnderlyingIds(state, rfqId, selectors)
    .filter(underlyingId => !selectors.getFullUnderlyingInfo(state, underlyingId));
}
