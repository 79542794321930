import type { AppState } from '@/bootstrap/state.ts';
import type { Selectors } from '@/bootstrap/selectors.ts';

export function getUnderlyingsIdsWithoutAnyInfo(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): string[] {
  return selectors
    .getRfqAndDeltaStrategiesUnderlyingIds(state, rfqId, selectors)
    .filter(underlyingId => !selectors.getUnderlyingInfo(state, underlyingId));
}
