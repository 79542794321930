import {
  type FullUnderlyingInfo,
  isFutureOptionProduct,
  isListedProduct,
  type Listed,
  type MarketLotSize,
  type MarketLotSizePointValue,
  type Product,
} from '../../../../../../../business/neosModel';

export const DefaultValueForOTCLotSize = {
  ID: '1',
} as const;

export interface LotSizeMarket {
  id: string;
  value: string | undefined;
}

export function buildDefaultResultToBuildLotSizeMarket(product: Product): LotSizeMarket {
  return {
    id: product.uuid,
    value: isListedProduct(product)
      ? `${product.lotSize} | ${product.marketMicCode ?? product.marketExchangeId}`
      : `${product.lotSize}`,
  };
}

export function buildLotSizeMarketFieldForOptionProduct(
  product: Product,
  underlyingInfo: FullUnderlyingInfo,
): LotSizeMarket {
  const isOptionOnFuture = isFutureOptionProduct(product);

  return {
    ...buildDefaultResultToBuildLotSizeMarket(product),
    value:
      isOptionOnFuture && underlyingInfo.option.optionOnFutureMarketLotSizes
        ? buildValueOptionProduct(product, underlyingInfo.option.optionOnFutureMarketLotSizes)
        : buildValueOptionProduct(product, underlyingInfo.option.marketLotSizes),
  };
}

export function buildLotSizeMarketFieldForOptionFlexProduct(
  product: Product,
  underlyingInfo: FullUnderlyingInfo,
): LotSizeMarket {
  return {
    ...buildDefaultResultToBuildLotSizeMarket(product),
    value: buildValueOptionProduct(product, underlyingInfo.option.marketLotSizesFlex),
  };
}

export function buildLotSizeMarketFieldForFutureListedProduct(
  product: Listed<Product>,
  underlyingInfo: FullUnderlyingInfo,
): LotSizeMarket {
  return buildLotSizeMarketFieldForFutureLikeListedProduct(product, underlyingInfo, 'future');
}

export function buildLotSizeMarketFieldForDividendFutureListedProduct(
  product: Listed<Product>,
  underlyingInfo: FullUnderlyingInfo,
): LotSizeMarket {
  return buildLotSizeMarketFieldForFutureLikeListedProduct(
    product,
    underlyingInfo,
    'dividendFuture',
  );
}

export function buildLotSizeMarketFieldForTotalReturnFutureListedProduct(
  product: Listed<Product>,
  underlyingInfo: FullUnderlyingInfo,
): LotSizeMarket {
  return buildLotSizeMarketFieldForFutureLikeListedProduct(
    product,
    underlyingInfo,
    'totalReturnFuture',
  );
}

function buildLotSizeMarketFieldForFutureLikeListedProduct(
  product: Listed<Product>,
  underlyingInfo: FullUnderlyingInfo,
  marketLotSizesProp: keyof Pick<
    FullUnderlyingInfo,
    'future' | 'dividendFuture' | 'totalReturnFuture'
  >,
): LotSizeMarket {
  const marketLotSizes = underlyingInfo[marketLotSizesProp]?.marketLotSizes ?? {};
  const keysFutureProduct = Object.keys(marketLotSizes);
  return {
    ...buildDefaultResultToBuildLotSizeMarket(product),
    value: buildValueFutureProduct(keysFutureProduct, product, marketLotSizes),
  };
}

function buildValueFutureProduct(
  keys: string[],
  product: Listed<Product>,
  marketLotSizes: { [id: string]: MarketLotSizePointValue },
): string | undefined {
  return keys.find(
    key =>
      marketLotSizes[key].market.galaxyCode === product.marketExchangeId &&
      marketLotSizes[key].lotSize === product.lotSize,
  );
}

function buildValueOptionProduct(
  product: Product,
  marketLotSizes: { [id: string]: MarketLotSize },
): string | undefined {
  const keys = Object.keys(marketLotSizes);

  if (isListedProduct(product)) {
    return keys.find(
      key =>
        marketLotSizes[key].market.galaxyCode === product.marketExchangeId &&
        marketLotSizes[key].lotSize === product.lotSize,
    );
  }

  return product.lotSize === 1
    ? DefaultValueForOTCLotSize.ID
    : keys.find(key => marketLotSizes[key].lotSize === product.lotSize);
}
