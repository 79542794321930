import type { Dispatchable, Thunk } from '@/bootstrap/thunks.ts';
import type {
  DividendLegPeriodDates,
  EquityLegPeriodDates,
  RateLegPeriodDates,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import { mapFromScheduleImportedData } from '@/neos/business/thunks/mapFromScheduleImportedData.ts';
import {
  scheduleDividendDatesSchema,
  scheduleEquityDatesSchema,
  scheduleRateDatesSchema,
  scheduleRateDatesWtihoutOvernightSchema,
  validateScheduleSchema,
} from '@/neos/business/thunks/scheduleImportedKeysValidation.ts';
import { getScheduleSchema } from '@/util/excel/excel.ts';
import { formatZodError } from '@/util/zod/zod-util.ts';

export function createImportScheduleLegsDataThunk(
  strategyId: string,
  importedData: unknown[],
): Thunk {
  return function importScheduleLegsDataThunk(
    dispatch,
    getState,
    { selectors, thunks: { neos: neosThunks, createErrorToasterThunk }, actionCreators },
  ) {
    const state = getState();
    const dispatchables: Dispatchable[] = [];

    const hasRateOverNightFeature = selectors.getFeature(state.featureState, {
      strategyId,
      type: 'RATE_OVERNIGHT',
    })?.rateOvernight;
    const excelDateFormat = selectors.selectExcelDateFormat(state.ui.userPreferences);

    const hideImportScheduleErrorAction = actionCreators.neos.strategyUiCrudActions.update(
      strategyId,
      {
        showImportScheduleError: false,
      },
    );

    dispatch(hideImportScheduleErrorAction);

    const removeOldScheduleThunks = [
      neosThunks.createRemoveAllEquitySchedulePeriodThunk(strategyId),
      neosThunks.createRemoveAllRateSchedulePeriodThunk(strategyId),
      neosThunks.createRemoveAllDividendSchedulePeriodThunk({
        type: 'DIVIDEND_COMPONENT',
        strategyId,
      }),
    ];

    dispatchables.push(...removeOldScheduleThunks);

    const scheduleParsingResult = getScheduleSchema(excelDateFormat).safeParse(importedData);

    if (!scheduleParsingResult.success) {
      const zodError = formatZodError(scheduleParsingResult.error);
      dispatch(
        createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const neosMappedImportedData = mapFromScheduleImportedData(scheduleParsingResult.data);

    try {
      neosMappedImportedData.forEach((line, index) => {
        const equityDates: Partial<EquityLegPeriodDates> = {
          startDate: line.equityStartDate,
          endDate: line.equityEndDate,
          paymentDate: line.equityPayDate,
        };
        const equityDatesValidationResult = validateScheduleSchema(
          equityDates,
          scheduleEquityDatesSchema,
        );
        if (!equityDatesValidationResult.success) {
          const zodError = formatZodError(
            equityDatesValidationResult.error,
            `Error trying to import equity dates at line ${index + 1}, make sure format match your user preferences`,
          );
          throw new Error(zodError);
        }

        dispatchables.push(
          neosThunks.createAddEquitySchedulePeriodThunk(strategyId, {
            startDate: equityDatesValidationResult.data.startDate ?? '',
            endDate: equityDatesValidationResult.data.endDate ?? '',
            paymentDate: equityDatesValidationResult.data.paymentDate ?? '',
          }),
        );

        const rateOverNightDates: Partial<RateLegPeriodDates> = {
          firstFixingDate: line.rateFirstFixingDate,
          fixingDate: line.rateFixingDate,
          startDate: line.rateStartDate,
          endDate: line.rateEndDate,
          paymentDate: line.ratePayDate,
        };
        const rateWithoutOverNightDates: Partial<RateLegPeriodDates> = {
          fixingDate: line.rateFixingDate,
          startDate: line.rateStartDate,
          endDate: line.rateEndDate,
          paymentDate: line.ratePayDate,
        };
        const rateDatesValidationResult = hasRateOverNightFeature
          ? validateScheduleSchema(rateOverNightDates, scheduleRateDatesSchema)
          : validateScheduleSchema(
              rateWithoutOverNightDates,
              scheduleRateDatesWtihoutOvernightSchema,
            );
        if (!rateDatesValidationResult.success) {
          const zodError = formatZodError(
            rateDatesValidationResult.error,
            `Error trying to import rate dates at line ${index + 1}, make sure format match your user preferences`,
          );
          throw new Error(zodError);
        }

        const rateDatesSchedule: RateLegPeriodDates & { firstFixingDate?: string } = {
          startDate: rateDatesValidationResult.data.startDate ?? '',
          endDate: rateDatesValidationResult.data.endDate ?? '',
          paymentDate: rateDatesValidationResult.data.paymentDate ?? '',
          fixingDate: rateDatesValidationResult.data.fixingDate ?? '',
        };
        if (hasRateOverNightFeature) {
          rateDatesSchedule.firstFixingDate = rateDatesValidationResult.data.firstFixingDate ?? '';
        }
        dispatchables.push(
          neosThunks.createAddRateSchedulePeriodThunk(strategyId, rateDatesSchedule),
        );

        const dividendDates: Partial<DividendLegPeriodDates> = {
          startDate: line.dividendStartDate,
          endDate: line.dividendEndDate,
          paymentDate: line.dividendPayDate,
          theoreticalPeriodPaymentDate: line.dividendTheoreticalPaymentDate,
        };
        const dividendsValidationResult = validateScheduleSchema(
          dividendDates,
          scheduleDividendDatesSchema,
        );
        if (!dividendsValidationResult.success) {
          const zodError = formatZodError(
            dividendsValidationResult.error,
            `Error trying to import dividend dates at line ${index + 1}, make sure format match your user preferences`,
          );
          throw new Error(zodError);
        }

        dispatchables.push(
          neosThunks.createAddDividendSchedulePeriodThunk(strategyId, {
            startDate: dividendsValidationResult.data.startDate ?? '',
            paymentDate: dividendsValidationResult.data.paymentDate ?? '',
            endDate: dividendsValidationResult.data.endDate ?? '',
            theoreticalPeriodPaymentDate:
              dividendsValidationResult.data.theoreticalPeriodPaymentDate ?? '',
          }),
        );
      });

      dispatch(dispatchables);
    } catch (e: unknown) {
      if (e instanceof Error) {
        // eslint-disable-next-line no-console
        console.error(e.message);
        dispatch(
          createErrorToasterThunk(
            {
              message: e.message,
            },
            undefined,
          ),
        );
      }
    }
  };
}
