import { importFromExcelClipboardData } from '@/util/excel/excel.ts';
import { useDispatch } from 'react-redux';
import { neosThunks } from '@/neos/business/thunks';
import { thunks } from '@/bootstrap/thunks.ts';

export function ElsScheduleClipBoardPasteButton(props: { strategyId: string }) {
  const dispatch = useDispatch();

  function handleError(error: string) {
    dispatch(thunks.createErrorToasterThunk({ message: error }, undefined));
  }

  function handleClipboardData(importedData: unknown[]) {
    dispatch(neosThunks.createImportScheduleLegsDataThunk(props.strategyId, importedData));
  }

  return (
    <button
      onClick={() => {
        importFromExcelClipboardData(handleClipboardData, handleError);
      }}
      className="btn btn-icon btn-discreet-primary"
    >
      <i className="icon">content_paste</i>
    </button>
  );
}
