import type { ChangeEvent } from 'react';
import * as XLSX from 'xlsx';
import { getDataFromClipboard } from '@/util/clipboard/clipboard.ts';
import { z } from 'zod';
import { DATE_SHORT_KEBAB_FORMAT, formatShort, isADate } from '@/util/date/dateFormatHelper.ts';
import { isMatch, isValid as isValidDate, parse } from 'date-fns';
import type { ExcelDateFormat } from '@/neos/business/ui/userPreferences/userPreferencesUiModel.ts';

export type ImportMode = 'clipboard' | 'file';

export function parseExcelFile<T>(
  fileData: string | ArrayBuffer | null,
  header: XLSX.Sheet2JSONOpts['header'] = undefined,
): T[] {
  const workbook = XLSX.read(fileData, {
    type: 'binary',
    cellText: false,
    cellDates: true,
  });

  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  return XLSX.utils.sheet_to_json<T>(sheet, {
    raw: false,
    rawNumbers: true,
    header,
    dateNF: DATE_SHORT_KEBAB_FORMAT,
  });
}

export async function importFromExcelClipboardData<T>(
  handleClipboardData: (data: T[]) => void,
  handleError: (error: string) => void,
) {
  const result = await getDataFromClipboard();
  if (result.status === 'FAILED') {
    handleError(result.message);
    return;
  }

  handleClipboardData(tsvToJson(result.data) as T[]);
}

export function exportDataToExcel<T extends Object>(data: T[], title: string): void {
  const headers = [Object.keys(data[0])];
  const ws = XLSX.utils.aoa_to_sheet(headers);

  XLSX.utils.sheet_add_json(ws, data, {
    header: Object.keys(data[0]),
    skipHeader: true,
    origin: -1,
  });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `${title}.xlsx`, { bookType: 'xlsx', type: 'binary', cellDates: true });
}

export function importExcelFileContent<T>(
  event: ChangeEvent<HTMLInputElement>,
  onImportDataCallback: (data: T[]) => void,
  config?: { withHeader?: boolean },
): void {
  event.persist();
  const reader = new FileReader();

  reader.onload = () => {
    const fileData = reader.result;
    const parsedData = parseExcelFile<T>(fileData, config?.withHeader ? 1 : undefined);
    return onImportDataCallback(parsedData);
  };

  const selectedFile = event.target.files?.item(0);
  if (selectedFile) {
    reader.readAsBinaryString(selectedFile);
  }
  event.target.value = '';
}

/*
  Transform tab separated values (tsv) with headers into json object
 */
function tsvToJson(tsvText: string): unknown[] {
  // Split all the text into seperate lines on new lines and carriage return feeds
  const allTextLines = tsvText.split(/\r\n|\n/);
  // Split on tabs
  const headers = allTextLines[0].split(/\t/);
  const lines = [];

  for (let i = 1; i < allTextLines.length; i++) {
    const data = allTextLines[i].split(/\t/);

    if (data.length == headers.length) {
      const row: Record<string, string> = {};
      for (let j = 0; j < headers.length; j++) {
        row[headers[j]] = data[j];
      }
      lines.push(row);
    }
  }

  return lines;
}

export const RawImportedDataSchema = z.array(z.record(z.string(), z.string()));
export type RawImportedDataSchemaType = z.infer<typeof RawImportedDataSchema>;

export type ScheduleSchemaOutput = Record<string, string | null>[];

export function getScheduleSchema(
  excelDateFormatUserPreference: ExcelDateFormat,
): z.ZodType<ScheduleSchemaOutput, any, any> {
  const stringDateSchema = getStringDateSchema(excelDateFormatUserPreference);

  return z.array(z.record(z.string(), stringDateSchema));
}

function getStringDateSchema(excelDateFormatUserPreference: ExcelDateFormat) {
  function customParser(value: unknown) {
    if (typeof value !== 'string') {
      return false;
    }

    // empty string is considered as empty cell
    if (value === '') {
      return true;
    }

    const dateFromDateString = parseDateFromDateString(value, excelDateFormatUserPreference);
    return isValidDate(dateFromDateString);
  }

  return z
    .custom<string>(customParser, input => {
      const errorMessage =
        typeof input === 'string'
          ? `Date '${input}' is not in ${excelDateFormatUserPreference} format`
          : `Expected date received wrong value`;

      return {
        message: errorMessage,
      };
    })
    .transform(value => transformDateStringToShortDateString(value, excelDateFormatUserPreference));
}

// Transform date string to yyyy-mm-dd date string
function transformDateStringToShortDateString(
  value: string,
  excelDateFormatUserPreference: ExcelDateFormat,
): string | null | 'error' {
  // empty string is considered as empty cell
  if (value === '') {
    return null;
  }

  if (isADate(value)) {
    return formatShort(value);
  }

  const date = parseDateFromDateString(value, excelDateFormatUserPreference);
  if (!isValidDate(date) || date === null) {
    throw new Error(
      `Cannot parse "${value}" date in the ${excelDateFormatUserPreference} user preferences format`,
    );
  }

  return formatShort(date);
}

const ambiguousParsingFormats: Record<ExcelDateFormat, string[]> = {
  ISO: ['dd/MM/yyyy', 'dd-MM-yyyy'],
  'EN-US': ['MM/dd/yyyy', 'MM-dd-yyyy'],
};

function parseDateFromDateString(value: string, excelDateFormat: ExcelDateFormat): Date | null {
  const newDate = new Date();

  // matches xx/xx/xxxx
  const slashDate = new RegExp(/^\d{1,2}\/\d{1,2}\/\d{2,4}$/);
  // matches xx-xx-xxxx
  const dashDate = new RegExp(/^\d{1,2}-\d{1,2}-\d{2,4}$/);

  const isAmbiguousDate = slashDate.test(value) || dashDate.test(value);

  if (!isAmbiguousDate) {
    return new Date(value);
  }

  const parsingFormat = ambiguousParsingFormats[excelDateFormat].find(format =>
    isMatch(value, format),
  );

  if (parsingFormat === undefined) {
    return null;
  }

  return parse(value, parsingFormat, newDate);
}
