import type { ChainOptions } from '../neosActions';
import type {
  PartialUnderlyingInfoRequestedAction,
  UnderlyingInfoRequestedAction,
} from './underlyingInfoActions';
import { underlyingInfoDomain } from './underlyingInfoDomain';

export const underlyingInfoActionCreators = {
  underlyingInfoCrudActions: underlyingInfoDomain.actions,
  createUnderlyingInfoRequestedAction,
  createPartialUnderlyingInfoRequestedAction,
};

function createUnderlyingInfoRequestedAction(
  rfqIds: string[],
  underlyingIds: string[],
  chainOptions?: ChainOptions,
): UnderlyingInfoRequestedAction {
  return {
    type: 'UNDERLYING_INFO_REQUESTED',
    rfqIds,
    underlyingIds,
    chainOptions,
  };
}

function createPartialUnderlyingInfoRequestedAction(
  rfqIds: string[],
  underlyingIds: string[],
  chainOptions?: ChainOptions,
): PartialUnderlyingInfoRequestedAction {
  return {
    type: 'PARTIAL_UNDERLYING_INFO_REQUESTED',
    rfqIds,
    underlyingIds,
    chainOptions,
  };
}
