import type { Thunk } from '@/bootstrap/thunks';
import type { UnderlyingChangeData } from '@/neos/business/neosSelectors/underlyingChangeData';
import { flatMap } from 'lodash';
import { internalGetErrorId } from '../../../mappers/error/internal/errorIdGenerator';
import type { OnyxFieldError } from '../../../mappers/error/onyxErrorModel';
import type { NeosActionCreators } from '../../../neosActionCreators';
import { isClsProduct, isElsProduct } from '../../models';

export function createChangeRfqUnderlyingThunk(
  rfqId: string,
  changeData: UnderlyingChangeData[],
  underlyingId: string | undefined,
): Thunk {
  return function changeRfqUnderlyingThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: { neos },
      thunks: {
        neos: {
          createChangeStrategyUnderlyingThunk,
          createRemoveStrategyFeaturesThunk,
          createHandleErrorsThunk,
          createRfqDeleteObsoleteUnderlyingDependenciesThunk,
          createDefaultProductLotSizeMarketThunk,
          createFetchCurvesListBasedOnCurrencyThunk,
        },
      },
    },
  ) {
    const state = getState();

    const { createDefaultUnderlyingRequestedAction } = neos;

    if (changeData.length > 0) {
      dispatch(
        changeData.map(data => [
          createChangeStrategyUnderlyingThunk(rfqId, data, underlyingId),
          createRemoveStrategyFeaturesThunk(data.affectedStrategyId),
        ]),
      );

      dispatch(createRfqDeleteObsoleteUnderlyingDependenciesThunk(rfqId));

      if (underlyingId) {
        const underlyingInfo = selectors.getFullUnderlyingInfo(state, underlyingId);
        if (!underlyingInfo) {
          throw Error(`Underlyings Info not found for underlying ${underlyingId}`);
        }

        const strategyIds = changeData.map(({ affectedStrategyId }) => affectedStrategyId);
        const defaultDelta = selectors.deltaStrategyExists(state, rfqId, selectors);
        const underlyingFieldErrors: OnyxFieldError[] = getUnderlyingFieldErrors(changeData);
        const resetErrors = getErrorReset(changeData, neos);

        const isDispersionEnabled = selectors.isFeatureToggleEnabled(
          state,
          'neos.rfq.dispersion.enabled',
        );

        const product = selectors.getRfqMasterProduct(state, rfqId, selectors);
        const isProductEls = isElsProduct(product);
        const isElsProductWithInterestRateScopeEnabled =
          selectors.isFeatureToggleEnabled(state, 'neos.els.scope.Interest.Rate.Index.enabled') &&
          isProductEls;

        const isCls = isClsProduct(product);

        const isTrader = selectors.isTrader(state);

        dispatch(
          createDefaultUnderlyingRequestedAction(
            rfqId,
            strategyIds,
            defaultDelta,
            {
              isDispersionEnabled,
              isElsProductWithInterestRateScopeEnabled,
            },
            {
              isElsProduct: isProductEls,
              isCls,
              isTrader,
              isElsIdb: selectors.isRfqInitiatedByTrader(state, rfqId),
            },
            {
              success: {
                dispatchables: [
                  createFetchCurvesListBasedOnCurrencyThunk(rfqId, strategyIds),
                  ...resetErrors,
                  createDefaultProductLotSizeMarketThunk(
                    flatMap(
                      strategyIds.map(stratId =>
                        selectors.getStrategyProductIds(state, stratId, selectors),
                      ),
                    ),
                  ),
                ],
              },
              error: {
                dispatchables: [
                  createHandleErrorsThunk(rfqId, underlyingFieldErrors),
                  ...changeData.map(data =>
                    createChangeStrategyUnderlyingThunk(rfqId, data, undefined),
                  ),
                ],
              },
            },
          ),
        );
      }
    }
  };
}

function getUnderlyingFieldErrors(changeData: UnderlyingChangeData[]): OnyxFieldError[] {
  return flatMap(
    changeData.map((data, stratIndex) =>
      data.affectedLegIds.map((_, legIndex) => ({
        field: `rfq.strategies[${stratIndex}].legs[${legIndex}].product.underlying`,
        message: 'Due to defaulting service error, underlying has been reset',
      })),
    ),
  );
}

function getErrorReset(
  changeData: UnderlyingChangeData[],
  { errorCrudActions }: NeosActionCreators,
) {
  return flatMap(
    changeData.map(data =>
      data.affectedLegIds.map(legId => {
        const errorId: string = internalGetErrorId('underlying')({
          legId,
          strategyId: data.affectedStrategyId,
        });
        return errorCrudActions.delete(errorId);
      }),
    ),
  );
}
