import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { OrderData } from '@/neos/business/order/orderModel';
import { orderOf } from '@/neos/business/services/statusOrder';
import type { DisplayTimeZone } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import type { Way } from '../../../../../neos/business/neosModel';
import {
  type BookingStepApplication,
  isUnderlyingIndexType,
  type OrderType,
  type UnderlyingType,
} from '../../../../../neos/business/neosOnyxModel';
import { getRefThresholdWarning, type RefThresholdWarning } from '../common/getAdjustedWarning';

interface DeltaAdjustedModel {
  strategyId: string;
  underlyingId: string;
  underlyingBloombergCode: string | undefined;

  warning: string | undefined;
  sgWay: Way | undefined;
  instrument: string | undefined;
  execComment: string | undefined;
  handlingInstruction: string | undefined;
  type: OrderType | undefined;
  timeInForce: string | undefined;
  limitPriceValue: number | undefined;
  limitPriceUnit: string | undefined;

  refSpot: number | undefined;
  refLevel: number | undefined;
  refCurrency: string | undefined;
  refType: UnderlyingType | undefined;

  averageExecPriceValue: number | undefined;
  averageExecPriceUnit: string | undefined;
  averageExecPriceWarning: RefThresholdWarning;

  execPercentage: number | undefined;
  execNumberOfLots: number | undefined;
  totalNumberOfLots: number | undefined;

  bookingId: string | undefined;
  isBookingIdManual: boolean;
  defaultBookingId: string | undefined;
  bookingApplication: BookingStepApplication | undefined;
  bookingMessage: string | undefined;
  portfolioId: string | undefined;
}

export interface DeltaAdjustedRefsModel {
  deltaAdjustedModels: DeltaAdjustedModel[];
  displayTimeZone: DisplayTimeZone;
  isReadonlyRfq: boolean;
  showBookingInfo: boolean;
  isAutoExecEnabled: boolean;
  isBookingIdDisplayed: boolean;
  isBookingIdReadonly: boolean;
  isBookingMessageDisplayed: boolean;
}

export function getDeltaAdjustedModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): DeltaAdjustedRefsModel {
  const {
    isReadOnlyRfq,
    getRfqData,
    getStrategyData,
    getUnderlyingOrRefIdOfStrategy,
    getReference,
    getStrategyMasterLeg,
    getStrategyWarnings,
    getDisplayTimeZone,
    getOrderByLegId,
    getFullUnderlyingInfo,
    isTrader,
    getBookingStepByStrategyId,
  } = selectors;

  const isReadonlyRfq = isReadOnlyRfq(state, rfqId);

  const { deltaHedgingStrategyIds, status } = getRfqData(state, rfqId);

  const displayTimeZone = getDisplayTimeZone(state.ui.userPreferences);
  const showBookingInfo = orderOf(status).isAfterOrEqual('BOOKING_REQUESTED');
  const averageExecPriceThreshold = 1;

  const deltaAdjustedModels = deltaHedgingStrategyIds
    .map((strategyId): DeltaAdjustedModel | undefined => {
      const strategyData = getStrategyData(state, strategyId);
      if (strategyData.scope !== 'DELTA_ADJUSTED') {
        return undefined;
      }

      const underlyingId = getUnderlyingOrRefIdOfStrategy(state, strategyId, selectors);

      if (!underlyingId) {
        throw Error('Delta adjusted strategy must have an underlying');
      }

      const {
        legIds: [legId],
        strategyType: instrument,
        comment: execComment,
        handlingInstruction,
        timeInForce,
        type,
        limitPriceValue,
        limitPriceUnit,
        portfolioId,
        bookingApplication,
        bookingId: strategyBookingId,
      } = strategyData;

      const bookingStep = getBookingStepByStrategyId(state, rfqId, strategyId);

      const isBookingIdManual = strategyBookingId !== undefined;

      const defaultBookingId = bookingStep?.bookingId;
      const bookingId = isBookingIdManual ? strategyBookingId : defaultBookingId;
      const bookingMessage = isBookingIdManual ? undefined : bookingStep?.message;

      const reference = getReference(state, {
        rfqId,
        underlyingId,
      });

      const underlyingInfo = getFullUnderlyingInfo(state, underlyingId);

      const { sgWay } = getStrategyMasterLeg(state, strategyId, selectors);
      const order = getOrderByLegId(state.orderData, rfqId, legId);

      const execPercentage = getExecPercentage(order);
      const warning = getStrategyWarnings(state, rfqId, strategyId, selectors)[0]?.warning;

      return {
        strategyId,
        underlyingId,
        underlyingBloombergCode: underlyingInfo?.bloombergCode,
        warning,
        sgWay,
        instrument,
        execComment,
        handlingInstruction,
        timeInForce,
        type,
        limitPriceValue,
        limitPriceUnit,
        refLevel: reference?.refLevel,
        refSpot: reference?.refSpot,
        refCurrency: underlyingInfo?.tradingPence ? 'GBp' : underlyingInfo?.currency,
        refType: underlyingInfo?.type,
        averageExecPriceValue: order?.averagePrice?.value,
        averageExecPriceUnit: order?.averagePrice?.unit,
        averageExecPriceWarning: reference
          ? getRefThresholdWarning(
              averageExecPriceThreshold,
              { value: order?.averagePrice?.value, type: 'Average Exec. Price' },
              reference,
              underlyingInfo,
            )
          : { isWarningDisplayed: false, warningText: undefined },
        execNumberOfLots: order?.execSize?.numberOfLots,
        totalNumberOfLots: order?.size.numberOfLots,
        execPercentage,
        portfolioId,
        isBookingIdManual,
        defaultBookingId,
        bookingApplication,
        bookingId,
        bookingMessage,
      };
    })
    .filter(isDefined);

  const isBookingIdDisplayed = deltaAdjustedModels.some(({ refType }) =>
    refType ? !isUnderlyingIndexType(refType) : false,
  );
  const isBookingIdReadonly = !isTrader(state);

  const isBookingMessageDisplayed = deltaAdjustedModels.some(
    ({ bookingMessage }) => bookingMessage,
  );

  return {
    deltaAdjustedModels,
    displayTimeZone,
    isReadonlyRfq,
    showBookingInfo,
    isAutoExecEnabled: selectors.isFeatureToggleEnabled(
      state,
      'neos.workflow.delta.stock.auto.enabled',
    ),
    isBookingIdDisplayed,
    isBookingIdReadonly,
    isBookingMessageDisplayed,
  };
}

function getExecPercentage(order: OrderData | undefined): number | undefined {
  const execNumberOfLots = order?.execSize?.numberOfLots;
  const numberOfLots = order?.size?.numberOfLots;
  if (execNumberOfLots === undefined || numberOfLots === undefined) {
    return undefined;
  }
  const result = (execNumberOfLots * 100) / numberOfLots;
  if (isNaN(result)) {
    return undefined;
  }
  return result;
}
