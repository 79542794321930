import { selectors } from '@/bootstrap/selectors';
import { FlowDatePicker } from '@/neos/components/share/datePicker';
import type { LegDescriptionComponentProps } from './AveragePrice';
import { useAppSelector } from '@/bootstrap/hooks.ts';
import { getProductType } from '@/neos/components/rfq/postNego/allocs/allocInfo/ProductType.tsx';
import type { DivSwap } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';

export function StartDate({ legId: productId }: LegDescriptionComponentProps) {
  const strategyType = useAppSelector(state => getProductType(state, productId, selectors));
  const product = useAppSelector(state => selectors.getProduct(state, productId)) as DivSwap;

  if (strategyType !== 'DIV_SWAP') {
    return null;
  }

  return (
    <div>
      <label className="mb-0 me-3">Start Date</label>
      <FlowDatePicker
        data-e2e="alloc-start-date"
        date={product.startDate ?? ''}
        maturities={[]}
        onChange={() => void 0}
        readOnly
        hideCalendarIcon
      />
    </div>
  );
}
