import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ToOnyxMappers } from '@/neos/business/mappers';
import type { UnderlyingInfo } from '@/neos/business/underlyingInfo/underlyingInfoModel';
import { isUnderlyingIndexType, isUnderlyingStockType } from '../rfqOnyxModel';
import type { Reference } from './referenceModel';
import type { OnyxStrategyReference } from './referenceOnyxModel';

export const toReferenceMappers = { mapToOnyxReferences, mapToOnyxReference };
export const fromReferenceMappers = { mapFromOnyxReferences };

function mapToOnyxReferences(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): OnyxStrategyReference[] {
  return selectors
    .getAllDistinctUnderlyingAndRefIdsOfStrategy(state, strategyId, selectors)
    .map(underlyingId => {
      const underlyingInfo = selectors.getUnderlyingInfo(state, underlyingId);

      if (!underlyingInfo) {
        throw Error(`underlyingInfo not found for underlying ${underlyingId}`);
      }

      return mappers.mapToOnyxReference(state, rfqId, underlyingInfo, selectors);
    });
}

function mapToOnyxReference(
  state: AppState,
  rfqId: string,
  underlyingInfo: UnderlyingInfo,
  selectors: Selectors,
): OnyxStrategyReference {
  const { id: underlyingId, type, tradingPence, currency } = underlyingInfo;
  const reference = selectors.getReference(state, {
    rfqId,
    underlyingId,
  });
  const {
    refMaturity,
    refSpot,
    refSpotUnit,
    refLevel,
    refBasis,
    refAdjusted,
    initialRefAdjusted,
    executedSpot,
    refSpotNet,
    refSpotNetUnit,
    isSpotConfirmed,
  } = reference ?? {
    refMaturity: undefined,
    refSpot: undefined,
    refSpotUnit: undefined,
    refSpotNet: undefined,
    refSpotNetUnit: undefined,
    executedSpot: undefined,
    refLevel: undefined,
    refBasis: undefined,
    refAdjusted: undefined,
    initialRefAdjusted: undefined,
    isSpotConfirmed: undefined,
  };

  const unit = tradingPence ? 'GBp' : currency;
  const spot =
    refSpot === undefined && refSpotUnit === undefined
      ? undefined
      : { value: refSpot, unit: refSpotUnit };
  const spotNet =
    refSpotNet === undefined ? undefined : { value: refSpotNet, unit: refSpotNetUnit };

  const onyxStrategyReference: OnyxStrategyReference = isUnderlyingIndexType(type)
    ? {
        discriminator: 'FUTURE',
        underlyingId,
        maturity: refMaturity!,
        spot,
        spotNet,
        executedSpot: executedSpot === undefined ? undefined : { value: executedSpot, unit },
        futureLevel: refLevel === undefined ? undefined : { value: refLevel, unit },
        adjustedFutureLevel: refLevel === undefined ? undefined : { value: refAdjusted, unit },
        basis: refBasis === undefined ? undefined : { value: refBasis, unit },
        initialAdjustedFutureLevel: initialRefAdjusted,
        isSpotConfirmed,
      }
    : isUnderlyingStockType(type)
      ? {
          discriminator: 'SPOT',
          underlyingId,
          spot,
          spotNet,
          adjustedSpot: refSpot === undefined ? undefined : { value: refAdjusted, unit },
          executedSpot: executedSpot === undefined ? undefined : { value: executedSpot, unit },
          initialAdjustedSpot: initialRefAdjusted,
          isSpotConfirmed,
        }
      : {
          discriminator: 'SPOT',
          underlyingId,
          spot,
          spotNet,
          adjustedSpot: refSpot === undefined ? undefined : { value: refAdjusted, unit },
          initialAdjustedSpot: initialRefAdjusted,
          isSpotConfirmed,
        };
  return onyxStrategyReference;
}

function mapFromOnyxReferences(onyxReferences: OnyxStrategyReference[]): Reference[] {
  return onyxReferences.map(onyxReference => {
    const reference: Omit<Reference, 'initialRefAdjusted'> = {
      refSpotNet: onyxReference?.spotNet?.value,
      refSpotNetUnit: onyxReference?.spotNet?.unit,
      underlyingId: onyxReference.underlyingId,
      refSpot: onyxReference?.spot?.value,
      refSpotUnit: onyxReference?.spot?.unit,
      isSpotConfirmed: onyxReference?.isSpotConfirmed,
    };

    return onyxReference.discriminator === 'FUTURE'
      ? {
          ...reference,
          refMaturity: onyxReference.maturity,
          refLevel: onyxReference?.futureLevel?.value,
          refBasis: onyxReference?.basis?.value,
          refAdjusted: onyxReference?.adjustedFutureLevel?.value,
          initialRefAdjusted: onyxReference?.initialAdjustedFutureLevel,
          executedSpot: onyxReference?.executedSpot?.value,
        }
      : {
          ...reference,
          refAdjusted: onyxReference?.adjustedSpot?.value,
          executedSpot: onyxReference?.executedSpot?.value,
          initialRefAdjusted: onyxReference?.initialAdjustedSpot,
        };
  });
}
