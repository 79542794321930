import type { AppState } from '@/bootstrap/state';

export function getUnderlyingOptionMaturities(state: AppState, underlyingId: string): string[] {
  const underlyingStateInfo = state.underlyingState[underlyingId];

  return underlyingStateInfo && underlyingStateInfo.hasAllUnderlyingInfo
    ? Object.keys(underlyingStateInfo.option.maturityInfo)
    : [];
}

export function getUnderlyingOptionFutureMaturities(
  state: AppState,
  underlyingId: string,
): string[] {
  const subState = state.underlyingState[underlyingId];

  return subState && subState.hasAllUnderlyingInfo && subState.option.optionOnFutureMaturityInfo
    ? Object.keys(subState.option.optionOnFutureMaturityInfo)
    : [];
}
