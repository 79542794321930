import { useAppSelector } from '@/bootstrap/hooks';
import { createLogAnalyticsAction } from '@/bootstrap/middlewares/analytics';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import type { ExcelDateFormat } from '@/neos/business/ui/userPreferences/userPreferencesUiModel.ts';

export function ExcelDateFormatPreferences() {
  const dispatch = useDispatch<ExtendedDispatch>();

  const changeExcelDateFormat = (excelDateFormat: ExcelDateFormat) => {
    dispatch(
      createLogAnalyticsAction('NEOS USER PREFERENCES', 'Change excel date format'),
      neosActionCreators.createExcelDateFormatChangedAction(excelDateFormat),
    );
  };

  const excelDateFormatUserPref = useAppSelector(state => state.ui.userPreferences.excelDateFormat);

  const navigatorDateFormatByLanguage: ExcelDateFormat = navigator.language.includes('en-us')
    ? 'EN-US'
    : 'ISO';

  return (
    <div className="d-grid mt-4">
      <span className="me-2 mb-2">Excel Date Format</span>
      <ToggleButtonGroup
        name="excelDateFormat"
        className="errorable-bloc btn-group-toggle"
        type="radio"
        onChange={changeExcelDateFormat}
        value={excelDateFormatUserPref}
      >
        <ToggleButton variant="toggle-primary" value="auto" id="date-auto">
          Auto ({navigatorDateFormatByLanguage})
        </ToggleButton>
        <ToggleButton className="d-flex gap-2" variant="toggle-primary" value="EN-US" id="EN-US">
          EN-US
          <span className="text-body-tertiary">[ mm/dd/yyyy ]</span>
        </ToggleButton>
        <ToggleButton className="d-flex gap-2" variant="toggle-primary" value="ISO" id="ISO">
          ISO
          <span className="text-body-tertiary">[ dd/mm/yyyy ]</span>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
