import { DATE_SHORT_KEBAB_FORMAT } from '@/util/date/dateFormatHelper';
import { type FC, forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import type { Tenor } from '../../../../neos/business/neosModel';
import { CustomDateInput } from './CustomDateInput';
import styles from './FlowDatePicker.module.scss';
import { PredefinedDates } from './PredefinedDates';
import { type Category, ShortcutCategorySwitcher } from './ShortcutCategorySwitcher';
import { Tenors } from './Tenors';
import { getKebabFormattedDateFromInput } from './getKebabFormattedDateFromInput';
import { useFlowDatePicker } from './useFlowDatePicker';

export type PopperPlacementType =
  | 'top'
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start'
  | undefined;

export interface FlowDatePickerProps {
  className?: string;
  inputClassName?: string;
  styleName?: string;
  maturities: string[];
  tenors?: Tenor[];
  hideCalendarIcon?: boolean;
  filterDate?: (date: Date) => boolean;
  date: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (selectedDateOrMaturity: string) => void;
  onChangeTenor?: (selectedTenor: string) => void;
  ['data-e2e']?: string;
  maturitiesCategory?: Category;
  popperPlacement?: PopperPlacementType;
  onCalendarClose?: () => void;
}

export const FlowDatePicker: FC<FlowDatePickerProps> = forwardRef<
  HTMLDivElement,
  FlowDatePickerProps
>(
  (
    {
      date,
      filterDate,
      maturities,
      tenors,
      disabled = false,
      readOnly = false,
      inputClassName = '',
      onChange,
      styleName,
      hideCalendarIcon,
      onChangeTenor,
      maturitiesCategory: propsCategory = 'LISTED',
      popperPlacement = undefined,
      onCalendarClose,
      ...restProps
    },
    parentRef,
  ) => {
    const [maturitiesCategory, setMaturitiesCategory] = useState<Category>(propsCategory);

    useEffect(() => {
      if (maturitiesCategory !== propsCategory) {
        setMaturitiesCategory(propsCategory);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propsCategory]);

    const submitInput = (inputValue: string | null) => {
      const newDateOrTenor = getKebabFormattedDateFromInput(inputValue, maturities, tenors ?? []);
      if (newDateOrTenor.type === 'TENOR' && onChangeTenor) {
        onChangeTenor(newDateOrTenor.tenorCode);
      } else if (newDateOrTenor.type === 'DATE' && newDateOrTenor.date !== date) {
        onChange(newDateOrTenor.date);
      }
    };

    const {
      ref,
      customInputRef,
      convertedDate,
      onInputClick,
      closeDatePicker,
      onDatePickerChange,
      onKeyDown,
    } = useFlowDatePicker(date, onChange);

    return (
      <div ref={parentRef} {...restProps}>
        <DatePicker
          ref={ref}
          customInput={
            <CustomDateInput
              ref={customInputRef}
              convertedDate={convertedDate}
              onCustomBlur={submitInput}
              closeDatePicker={closeDatePicker}
              disabled={disabled}
              hideCalendarIcon={hideCalendarIcon}
              className={inputClassName}
              readOnly={readOnly}
            />
          }
          portalId="root"
          readOnly={readOnly}
          onInputClick={onInputClick}
          peekNextMonth
          dropdownMode="select"
          selected={convertedDate}
          locale={'en'}
          dateFormat={DATE_SHORT_KEBAB_FORMAT}
          filterDate={filterDate}
          fixedHeight
          disabledKeyboardNavigation
          onChange={onDatePickerChange}
          onKeyDown={onKeyDown}
          disabled={disabled}
          calendarClassName={`${styles.calendar} panel panel-default`}
          popperClassName={styles.popper}
          popperPlacement={popperPlacement}
          onCalendarClose={onCalendarClose}
        >
          <div className={`${styles['container-maturities']} `}>
            <ShortcutCategorySwitcher
              displayed={!!tenors?.length && !!maturities.length}
              currentCategory={maturitiesCategory}
              onClick={() =>
                setMaturitiesCategory(maturitiesCategory === 'LISTED' ? 'TENORS' : 'LISTED')
              }
            />
            <PredefinedDates
              isDisplayed={maturitiesCategory === 'LISTED'}
              predefinedDates={maturities}
              onSelect={date => (onChange(date), closeDatePicker())}
              selectedDate={date || ''}
              tenors={tenors}
            />
            <Tenors
              isDisplayed={maturitiesCategory === 'TENORS'}
              tenors={tenors ?? []}
              onSelect={tenor => (onChangeTenor?.(tenor), closeDatePicker())}
              currentMaturity={date || ''}
            />
          </div>
        </DatePicker>
      </div>
    );
  },
);
