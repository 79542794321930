import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import { isFutureProduct, isOptionLike, type Reference } from '../../../../../business/neosModel';
import { getRefThresholdWarning, type RefThresholdWarning } from '../../common/getAdjustedWarning';

interface RefAdjustedField {
  isRefAdjustedDisplayed: boolean;
  refAdjusted: number | undefined;
}

interface RefAdjustedByUnderlyingModel {
  deltaId: string | undefined;
  underlyingId: string;
  isReadonly: boolean;
  refAdjustedField: RefAdjustedField;
  refAdjustedWarning: RefThresholdWarning;
}

export interface RefAdjustedModel {
  refAdjustedModels: RefAdjustedByUnderlyingModel[];
}

export function getRefAdjustedModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): RefAdjustedModel {
  const refAdjustedThreshold = 1;
  const references = selectors.getNotAggregatedReferences(state, rfqId, selectors);
  const isRfqReadonly = selectors.isReadOnlyRfq(state, rfqId);
  const isRfqReadOnlyAtCurrentWorkflow = selectors.isRfqReadOnlyAtCurrentWorkflow(
    state,
    rfqId,
    selectors,
  );
  const { deltaHedgingStrategyIds } = selectors.getRfqData(state, rfqId);
  const deltaHedgingProducts = deltaHedgingStrategyIds.map(id => ({
    deltaId: id,
    product: selectors.getStrategyMasterProduct(state, id, selectors),
  }));

  const refAdjustedModels = references.map((reference): RefAdjustedByUnderlyingModel => {
    const underlyingInfo = selectors.getFullUnderlyingInfo(state, reference.underlyingId);
    const deltasByUnderlying = deltaHedgingProducts.filter(({ product }) => {
      if (!isOptionLike(product) && !isFutureProduct(product)) {
        return false;
      }

      return product.underlyingId === reference.underlyingId;
    });
    return {
      deltaId: deltasByUnderlying.length > 0 ? deltasByUnderlying[0].deltaId : undefined,
      isReadonly: isRfqReadonly || isRfqReadOnlyAtCurrentWorkflow,
      underlyingId: reference.underlyingId,
      refAdjustedField: {
        isRefAdjustedDisplayed: isRefAdjustedDisplayed(rfqId, selectors, state, reference),
        refAdjusted: reference.refAdjusted,
      },
      refAdjustedWarning: getRefThresholdWarning(
        refAdjustedThreshold,
        { value: reference.refAdjusted, type: 'Ref Adjusted' },
        reference,
        underlyingInfo,
      ),
    };
  });
  return { refAdjustedModels };
}

function isRefAdjustedDisplayed(
  rfqId: string,
  selectors: Selectors,
  state: AppState,
  reference: Reference,
) {
  const { savedStatus } = selectors.getRfqData(state, rfqId);
  const isStatusAfterOrEqualOrderAccepted =
    savedStatus !== undefined && services.orderOf(savedStatus).isAfterOrEqual('ORDER_RECEIVED');

  return (
    isStatusAfterOrEqualOrderAccepted &&
    atLeastOneStrategyIsDeltaAdjusted(state, rfqId, reference, selectors)
  );
}

function atLeastOneStrategyIsDeltaAdjusted(
  state: AppState,
  rfqId: string,
  reference: Reference,
  selectors: Selectors,
) {
  const hedge = selectors.getHedge(state, {
    rfqId,
    underlyingId: reference.underlyingId,
    deltaType: 'DELTA_ADJUSTED',
  });
  return !!hedge;
}
