import { thunks } from '@/bootstrap/thunks';
import { useDispatch } from 'react-redux';
import { ElsBuyAndSellGridRow } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/BuyAndSellBloc/ElsBuyAndSellGridRow.tsx';
import { ElsFeaturesContext } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/ElsFeatures.tsx';
import { useContext } from 'react';
import { useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import { formatGridTemplateColumns } from '@/util/format/formatGridTemplateColumns.ts';

export function ElsBuyAndSellComponents() {
  const dispatch = useDispatch();
  const { product, rfqId } = useContext(ElsFeaturesContext);
  const isFeatureToggleEnabled = useAppSelector(state =>
    selectors.isFeatureToggleEnabled(state, 'neos.buy.and.sell.add.disabled'),
  );
  const isInternalPortfolioDisplayed = useAppSelector(state =>
    selectors.isRfqInternalEls(state, rfqId, selectors),
  );

  const onAddClick = () => {
    if (isFeatureToggleEnabled) {
      return;
    }

    dispatch(thunks.neos.createAddNewElsEquityHedgeComponentThunk(rfqId, product.uuid));
  };

  return (
    <div
      className="d-grid align-items-center gap-1 overflow-auto"
      style={{
        gridTemplateColumns: formatGridTemplateColumns([
          '32px repeat(5, minmax(80px, 1fr))',
          'minmax(250px, auto)',
          'minmax(80px, 1fr) minmax(80px, 1fr)',
          [isInternalPortfolioDisplayed, 'minmax(80px, 1fr)'],
          'repeat(2, minmax(100px, auto))',
        ]),
        maxHeight: '400px',
      }}
      onMouseDown={e => e.stopPropagation()}
    >
      {isFeatureToggleEnabled ? (
        <div />
      ) : (
        <button className="btn btn-icon btn-flat-primary" onClick={onAddClick}>
          <i className="icon icon-md">add</i>
        </button>
      )}
      <label className="fw-bold">Underlying</label>
      <label className="fw-bold">Quantity</label>
      <label className="fw-bold">Spot</label>
      <label className="fw-bold">Spot Net</label>
      <label className="fw-bold">Nominal in swap ccy</label>
      <label className="fw-bold">Counterpart</label>
      <label className="fw-bold">Broker</label>
      <label className="fw-bold">Portfolio</label>
      {isInternalPortfolioDisplayed && <label className="fw-bold">Internal Ptf</label>}
      <label className="fw-bold">Type</label>
      <label className="fw-bold">Way</label>
      {/*<label className="fw-bold">Booking ID</label>*/}

      {product.equityHedge?.equityHedgeComponents?.map((equityComponent, index) => (
        <ElsBuyAndSellGridRow
          isInternalPortfolioDisplayed={isInternalPortfolioDisplayed}
          equityComponent={equityComponent}
          index={index}
          key={equityComponent.uuid}
        />
      ))}
    </div>
  );
}
