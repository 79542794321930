import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { BookingStep } from '@/neos/business/bookingSteps/BookingStepsSelectors';
import type { Execution } from '@/neos/business/order/orderModel';
import type { BookingStepStatus } from '../../../../../business/neosOnyxModel';
import {
  getUiBookingStatus,
  type UiBookingStatus,
} from '../../../../share/bookingStatusStyle/bookingStatusStyle';

export interface DeltaAdjustedBookingModel {
  bookingStatuses: {
    underlyingLabel: string | undefined;
    executionsBookingStep: {
      execution: Execution;
      bookingStep: BookingStep | undefined;
      uiBookingStep: UiBookingStatus | undefined;
    }[];
    aggregatedBadge: UiBookingStatus;
  }[];
}

export function getDeltaAdjustedBookingModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): DeltaAdjustedBookingModel {
  const { getRfqData, getStrategyData, getLegUnderlyingLabel, getUnderlyingIdOrNameOrRefIdOfLeg } =
    selectors;
  const { executionSelectors, getDeltaExecutionBookingStep } = selectors;
  const { getUnderlyingInfo } = selectors;

  const deltaAdjustedStrategies = getRfqData(state, rfqId).deltaHedgingStrategyIds.filter(
    sId => getStrategyData(state, sId).scope === 'DELTA_ADJUSTED',
  );

  const bookingStatuses = deltaAdjustedStrategies.map(strategyId => {
    const {
      legIds: [legId],
    } = getStrategyData(state, strategyId);
    const underlyingLabel = getLegUnderlyingLabel(state, legId, selectors);
    const udlId = getUnderlyingIdOrNameOrRefIdOfLeg(state, legId, selectors);
    const udlType = udlId ? getUnderlyingInfo(state, udlId)?.type : undefined;

    const executions = executionSelectors.selectObjects(state.execution, {
      legId,
    });

    const executionsBookingStep = executions.map(e => {
      const bs = getDeltaExecutionBookingStep(state, rfqId, e.uuid);
      return {
        execution: e,
        bookingStep: bs,
        uiBookingStep: getUiBookingStatus(bs?.status),
      };
    });
    const bookingStepStatuses = executionsBookingStep.map(bs => bs?.bookingStep?.status);
    const aggBookingStep = getAggBookingStepStatus(bookingStepStatuses);

    const aggBooking = getUiBookingStatus(aggBookingStep);
    const aggregatedBadge: typeof aggBooking =
      aggBooking.name === '' &&
      executionsBookingStep.some(e => e.execution.size?.quantity) &&
      udlType === 'INDEX'
        ? { name: 'Details', className: 'bg-primary' }
        : aggBooking;

    return {
      aggregatedBadge,
      underlyingLabel,
      executionsBookingStep,
    };
  });

  return {
    bookingStatuses,
  };
}

export function getAggBookingStepStatus(
  bookingStepStatuses: (BookingStepStatus | undefined)[],
): BookingStepStatus | undefined {
  const filteredBookigStatuses = bookingStepStatuses.filter(
    bs => bs === 'FAILED' || bs === 'PENDING' || bs === 'BOOKED' || bs === 'UPDATED',
  );
  return filteredBookigStatuses.length === 0
    ? undefined
    : filteredBookigStatuses.some(bs => bs === 'FAILED')
      ? 'FAILED'
      : filteredBookigStatuses.some(bs => bs === 'PENDING')
        ? 'PENDING'
        : filteredBookigStatuses.every(bs => bs === 'BOOKED' || bs === 'UPDATED')
          ? 'BOOKED'
          : undefined;
}
